import './App.css';
import { TextImage } from './sections/TextImage';
import { Header } from './sections/Header';
import { Text } from './sections/Text';
import { Footer } from './sections/Footer';
import { Phrase } from './sections/Phrase';
import { Contact } from './sections/Contact';
import { WhatsAppIcon } from './components/WhatsappIcon';
import { Review } from './sections/Review';

function App() {
  return (
    <div className="App" style={{backgroundImage: `url('/textura.png')`, backgroundSize: 'cover', backgroundPosition: 'center'}} alt="Foto Principal"> 
      <WhatsAppIcon/>
      <Header/>
      <TextImage 
        title={['<b>Terapia Online com Psicóloga Especialista.</b>']}
        paragraphs={['Faça suas sessões de qualquer lugar com atendimento online para superar ansiedade, estresse e outros desafios.']} 
        image={'/fabi1.png'}
        no_image={false}
        altImage={'Foto Principal'}
        imageRightSide={true}
        fontColorTitles={'paleta-cor1'}
        fontColorParagraphs={'paleta-cor1'}
        fontColorSubtitle={'rose'}
        buttonColor={'paleta-cor1'}
      />

      <Phrase 
          titleFont={'montserrat'} 
          title={'Atendimento para jovens, adultos e idosos'} 
          paragraphs={['Atendimento Online e Presencial em Criciúma-SC']} 
          background={'paleta-cor4'} 
          buttonColor={'paleta-cor1'}
      />
      
      <Text titleFont={'montserrat'} 
            subtitle={'Abordagem'} 
            title={'Você já tentou terapia e sentiu que não avançou?'} 
            paragraphs={
                [
                  'Quero te mostrar como a <b>Terapia do Esquema</b> pode transformar seu processo!',
                  'Muita gente acha que terapia é só falar sobre o passado, mas a Terapia do Esquema vai além!',
                  'Ela te ajuda a entender padrões emocionais que te fazem repetir os mesmos erros e, mais importante, a mudá-los.',
                  'Se você se sente preso em <b>ciclos de insegurança</b>, <b>vícios</b>, <b>procrastinação</b> ou <b>relacionamentos frustrantes</b>, esse método te dá ferramentas para agir diferente, sem precisar reviver tudo o que te machucou.',
                  'Se você quer experimentar essa forma de terapia e descobrir como ela pode te ajudar, clique no botão abaixo e agende uma consulta!'
                ]
              } 
            background={'white'}
            buttonColor={'paleta-cor1'}
            titleColor={'paleta-cor1-color'}
            textColor={'paleta-cor1-color'}
            retreat={false}
      />

      <TextImage  
        title={['Fabiana Manarim Dias','<small>CRP-12/27236</small>']}
        subtitle={'Sobre mim'}
        no_image={true}
        paragraphs={
          [
            'Sou casada a 25 anos, mãe de dois filhos e ajudo pessoas através da psicóloga clínica. Apaixonada por aprender e ensinar sobre emoções, minhas proprias experiências com traumas me motivou a seguir a psicologia.', 
            'Utilizo a <b>Terapia do Esquema</b> para explorar como as experiências anteriores moldam os comportamentos e emoções na vida adulta. A partir desse entendimento, desenvolvo planos que promovem mudanças nos comportamentos indesejados.',
            'Compreender as raízes dos nossos problemas é o primeiro passo para a cura. ', 
            'Meu compromisso é oferecer um espaço seguro e acolhedor, onde você será ouvido(a) e compreendido(a). Estou aqui para ajudar você a se reconectar com sua essência, superar seus medos e construir a vida que deseja.'
          ]
        } 

        altImage={'Psicologa Fabiana M. Dias'}
        imageRightSide={false}
        background={'white'}
        fontColorTitles={'paleta-cor1'}
        fontColorSubtitle={'paleta-cor1'}
        fontColorParagraphs={'paleta-cor1'}
        buttonColor={'paleta-cor1'}
        colorHr={'paleta-cor1'}
        fontSize='1.1rem'
        backgroundImage={'background-image-bege2'}
      />


      {/*<Slide/>*/}
      <Review reviews={['"<i>Procurar a Fabiana foi a melhor decisão que tomei para mim mesma. A forma como conduz as sessões é excelente. Ela entende exatamente o que sinto. Descobri traumas de infância que ainda impactavam minha vida adulta, e hoje, com sua ajuda, transformei essas dores em força e autoconfiança."<i><br><br>— C.M. 43 anos —',
                        '"<i>Fabiana me ajudou em um processo de autoconhecimento que eu nem sabia que era possível. Sua compreensão profunda me fez entender as causas de comportamentos que me impediam de ser feliz. Estou muito satisfeita e evoluo a cada sessão."<i><br><br>— R.P. 22 anos —',
                        '"<i>Desde que comecei a terapia com Fabiana, minha forma de lidar com emoções e relacionamentos mudou completamente. Ela é incrível, traz clareza para os problemas e cria um ambiente seguro para explorar minhas dificuldades. O impacto na minha vida tem sido imenso."<i><br><br>— A.R. 76 anos —']}
              fontColorTitles={'paleta-cor1'}
      />
      <Contact 
          title={"Estou aqui para te ouvir com empatia e acompanhar seu processo de transformação."} 
          sentence={["<b>Agende sua consulta!</b>"]} 
          phone={"(48) 99168-2656"} 
          mail={"fabi.dias78@gmail.com"} 
          adress={"Criciúma-SC"}
          fontColorTitles={'paleta-cor1'}
          fontColorParagraphs={'paleta-cor1'}
      />
      <Footer/>
      </div>
    );
}

export default App;


