import './style.css'

export const Footer = () => {
    return (
      <footer className="footer-section background paleta-cor1">
        <div className="footer-content">
          <p>© 2025 <a style={{textDecoration: 'none', color: '#F8F8F8'}} href='https://wa.me/5548991682656' target="_blank" rel="noopener noreferrer">Fabiana Dias</a>. Todos os direitos reservados.</p>
        </div>
      </footer>
    );
  };