import { ContactButton } from '../../components/contact-button';
import './style.css'

export const Phrase = ({title, paragraphs, paragraphBold, background, buttonColor}) => {
    const className = `border ${background} background-image-phase`
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };
    return (
        <div className={className}>
            <div className="page-container">
            <div className="phrase-section">
                <h1 className="phrase-title">{title}</h1>
                {paragraphs?.map((element, index) => (
                    <p style={{fontSize: '1.5rem'}} key={index} dangerouslySetInnerHTML={createMarkup(element)}></p>
                ))}
                <b>{paragraphBold}</b>
                <br/>
                <div style={{paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ContactButton color={buttonColor}/>
                </div>
            </div>
            </div>
        </div>
      );
  };
  