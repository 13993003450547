import { ContactButton } from '../../components/contact-button';
import './style.css'

export const TextImage = ({subtitle, title, paragraphs, paragraphBold, image, altImage, imageRightSide, 
                            background, fontColorParagraphs, fontColorTitles, fontColorSubtitle, colorHr, 
                            buttonColor = 'rose', 
                            fontSize = '1.2rem',
                            backgroundImage = '',
                            no_image=false
                        }) => {
    const styleMain = `border ${background != null ? background : ""} ${backgroundImage}`;
    const subtitleClassName = `montserrat-subtitle ${fontColorSubtitle}-color`;
    const titleClassName = `text-title ${fontColorTitles}-color`;
    const titleClassNameSmall = `text-title-small ${fontColorTitles}-color`;
    const paragraphsClassName = `${fontColorParagraphs}-color ${imageRightSide}`;
    const hrClassName = `${colorHr}`;
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };
    return (
        <div className={styleMain}>
            <div className="page-container">
            {!imageRightSide && !no_image &&
                <div className="image-text-image">
                    <img className="roundCorner" src={image} alt={altImage} />
                </div>  
            }
            <div className="text-text-image">
                {subtitle != null && <h2 className={subtitleClassName}>{subtitle}<hr className={hrClassName}/></h2>}
                {title?.map((element, index) => (
                    index === 0 ? (
                        <h1 className={titleClassName} key={index} dangerouslySetInnerHTML={createMarkup(element)}></h1>
                    ) : (
                        <h1 className={titleClassNameSmall} key={index} dangerouslySetInnerHTML={createMarkup(element)}></h1>
                    )                
                ))}
                {paragraphs?.map((element, index) => (
                    <p style={{fontSize: fontSize}} className={paragraphsClassName} key={index} dangerouslySetInnerHTML={createMarkup(element)}></p>
                    ))}
                <b>{paragraphBold}</b>
                <br/>
                <div style={{paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ContactButton color={buttonColor}/>
                </div>
            </div>
            {imageRightSide && !no_image &&
                <div className="image-text-image">
                    <img src={image} alt={altImage} />
                </div>
            }
            </div>
        </div>
      );
  };
  