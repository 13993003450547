import { WhatsAppOutlined } from '@ant-design/icons';
import './style.css'

export const ContactButton = ({color}) => {
    const className = `${color} info-button white-color`
    const handleClick = () => {
        window.gtag_report_conversion();
        window.fbq('track', 'Contact');
        window.open('https://wa.me/5548991682656?text=Ol%C3%A1%2C%20venho%20atrav%C3%A9s%20do%20seu%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%A3o%20sobre%20a%20consulta%20em%20psicoterapia.', '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            <button onClick={handleClick} className={className}><WhatsAppOutlined style={{ fontSize: '20px', marginBottom: '-3px' }}/>Quero Agendar Agora</button>
        </>
      );
  };